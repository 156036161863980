import heroImage from "./images/1.png";

export default function HeroComp() {
    return (
        <div className="hero container-fluid">
            <div className="row align-items-center h-100">
                <div className="col-12 col-sm-6">
                    <img className="img-fluid pt-5" src={heroImage} alt="Hero" />
                </div>
                <div className="col-12 col-sm-6">
                    <h1 className="display-1 text-white">Local To Global ...</h1>
                    <h4 className="subfoot">One trade at a time</h4>
                </div>
            </div>
        </div>
    );
}