import Menubar from "./Menubar";
import HeroComp from "./hero";

function App() {
  return (
    <div className="position-relative">
      <Menubar />
      <HeroComp />
    </div>
  );
}

export default App;
