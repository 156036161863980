export default function Menubar() {
    return (
        <>
            <div className="menubar position-absolute container-fluid">
                <nav className="navbar navbar-expand-lg justify-content-between">
                    <a className="navbar-brand" href="/">
                        <div className="text-white sinesta">Sinesta</div>
                        <div className="subfoot overseas">Overseas</div>
                    </a>
                    <button
                        className="btn btn-outline-info subfoot"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal">Contact Us</button>
                </nav>
            </div>
            <div
                className="modal fade"
                id="exampleModal"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel">Contac Information</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <h1 className="display-6">Akshay Sarda</h1>
                            <p>Managing Partner</p>
                            <p className="lead">+91 9604 811 911</p>
                            <p className="lead">contact@sardas.co.in</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}